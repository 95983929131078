
import { computed, reactive, ref, defineComponent } from "vue"
import { useStore } from "vuex"
import { setup, Vue } from "vue-class-component"
import { usePerms } from "../logic/usePerms"
import axios from "axios"

export default defineComponent({
  components: {},
  setup() {
    /* eslint-disable */
    const store = useStore()
    const { hasPerm, checkToken } = usePerms()

    const login = (email: string, password: string) => {
      store.dispatch("auth/login", { email, password })
    }
    const logout = () => {
      store.dispatch("auth/logout")
    }

    const token = computed(() => store.getters["auth/token"])
    const host = computed(() => store.getters["auth/host"])

    const projects = ref<any[]>([])

    // const url = "https://api.otonomi.no"
    const url = "http://localhost:8085"
    const loadProjects = async () => {
      await checkToken()

      if (hasPerm("system.admin")) {
        axios({
          baseURL: url,
          url: "/project",
          data: {},
          method: "GET",
          headers: {
            Authorization: "Bearer " + token.value,
          },
        })
          .then((resp) => {
            console.log(resp)
            projects.value = resp.data
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
    const pname = ref("")
    const addproject = async () => {
      await checkToken()

      if (hasPerm("system.admin")) {
        axios({
          baseURL: url,
          url: "/project",
          data: { projectName: pname.value },
          method: "POST",
          headers: {
            Authorization: "Bearer " + token.value,
          },
        })
          .then((resp) => {
            console.log(resp)
            projects.value.push(resp.data)
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
    const project = ref<any>({})
    const getproject = async (id: any) => {
      await checkToken()

      if (hasPerm("system.admin")) {
        axios({
          baseURL: url,
          url: "/project/" + id,
          data: {},
          method: "GET",
          headers: {
            Authorization: "Bearer " + token.value,
          },
        })
          .then((resp) => {
            project.value = resp.data
            console.log(resp)
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
    const delproject = async (id: any) => {
      await checkToken()

      if (hasPerm("system.admin")) {
        axios({
          baseURL: url,
          url: "/project/" + id,
          data: {},
          method: "DELETE",
          headers: {
            Authorization: "Bearer " + token.value,
          },
        })
          .then((resp) => {
            console.log(resp)
            if (resp.status == 200) {
              projects.value = projects.value.filter((v) => {
                return v._id != id
              })
            }
          })
          .catch((err: any) => {
            console.log(err)
          })
      }
    }
    const addjob = async (id: any) => {
      await checkToken()

      if (hasPerm("system.admin")) {
        axios({
          baseURL: url,
          url: "/job/" + id,
          data: {},
          method: "POST",
          headers: {
            Authorization: "Bearer " + token.value,
          },
        })
          .then((resp) => {
            console.log(resp)
            if (resp.data.project == project.value._id) {
              project.value.jobs.push(resp.data)
            }
          })
          .catch((err: any) => {
            console.log(err)
          })
      }
    }
    const ifcfile = ref<any>("")
    const jobid = ref("")
    const progress = ref(0)
    const uploadifc = async (id: any) => {
      await checkToken()

      if (hasPerm("system.admin")) {
        let formData = new FormData()

        formData.append("file", ifcfile.value)
        console.log(formData)
        axios({
          baseURL: url,
          url: "/job/ifc/" + jobid.value,
          data: formData,
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token.value,
          },
          onUploadProgress: function (progressEvent: any) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            progress.value = percentCompleted
            console.log(percentCompleted)
          },
        })
          .then((resp) => {
            console.log(resp)
            // if (resp.data.project == project.value._id) {
            // project.value.jobs.push(resp.data)
            // }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }

    const sendEmail = async () => {
      await checkToken()

      if (hasPerm("system.admin")) {
        axios({
          baseURL: url,
          url: "/email",
          data: {},
          method: "POST",
          headers: {
            Authorization: "Bearer " + token.value,
          },
        })
          .then((resp) => {
            console.log(resp)
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }

    loadProjects()
    return {
      islogedIn: computed(() => store.getters["auth/isLogedIn"]),
      username: computed(() => store.getters["auth/username"]),
      login,
      logout,
      hasPerm,
      pname,
      addproject,
      getproject,
      delproject,
      addjob,
      ifcfile,
      uploadifc,
      projects,
      project,
      jobid,
      progress,
      sendEmail,
    }
  },
})
