

<script lang="ts">
import { computed, reactive, ref, defineComponent } from "vue"
import { useStore } from "vuex"
import { setup, Vue } from "vue-class-component"
import { usePerms } from "../logic/usePerms"
import axios from "axios"

export default defineComponent({
  components: {},
  setup() {
    /* eslint-disable */
    const store = useStore()
    const { hasPerm, checkToken } = usePerms()

    const login = (email: string, password: string) => {
      store.dispatch("auth/login", { email, password })
    }
    const logout = () => {
      store.dispatch("auth/logout")
    }

    const token = computed(() => store.getters["auth/token"])
    const host = computed(() => store.getters["auth/host"])

    const projects = ref<any[]>([])

    // const url = "https://api.otonomi.no"
    const url = "http://localhost:8085"
    const loadProjects = async () => {
      await checkToken()

      if (hasPerm("system.admin")) {
        axios({
          baseURL: url,
          url: "/project",
          data: {},
          method: "GET",
          headers: {
            Authorization: "Bearer " + token.value,
          },
        })
          .then((resp) => {
            console.log(resp)
            projects.value = resp.data
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
    const pname = ref("")
    const addproject = async () => {
      await checkToken()

      if (hasPerm("system.admin")) {
        axios({
          baseURL: url,
          url: "/project",
          data: { projectName: pname.value },
          method: "POST",
          headers: {
            Authorization: "Bearer " + token.value,
          },
        })
          .then((resp) => {
            console.log(resp)
            projects.value.push(resp.data)
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
    const project = ref<any>({})
    const getproject = async (id: any) => {
      await checkToken()

      if (hasPerm("system.admin")) {
        axios({
          baseURL: url,
          url: "/project/" + id,
          data: {},
          method: "GET",
          headers: {
            Authorization: "Bearer " + token.value,
          },
        })
          .then((resp) => {
            project.value = resp.data
            console.log(resp)
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
    const delproject = async (id: any) => {
      await checkToken()

      if (hasPerm("system.admin")) {
        axios({
          baseURL: url,
          url: "/project/" + id,
          data: {},
          method: "DELETE",
          headers: {
            Authorization: "Bearer " + token.value,
          },
        })
          .then((resp) => {
            console.log(resp)
            if (resp.status == 200) {
              projects.value = projects.value.filter((v) => {
                return v._id != id
              })
            }
          })
          .catch((err: any) => {
            console.log(err)
          })
      }
    }
    const addjob = async (id: any) => {
      await checkToken()

      if (hasPerm("system.admin")) {
        axios({
          baseURL: url,
          url: "/job/" + id,
          data: {},
          method: "POST",
          headers: {
            Authorization: "Bearer " + token.value,
          },
        })
          .then((resp) => {
            console.log(resp)
            if (resp.data.project == project.value._id) {
              project.value.jobs.push(resp.data)
            }
          })
          .catch((err: any) => {
            console.log(err)
          })
      }
    }
    const ifcfile = ref<any>("")
    const jobid = ref("")
    const progress = ref(0)
    const uploadifc = async (id: any) => {
      await checkToken()

      if (hasPerm("system.admin")) {
        let formData = new FormData()

        formData.append("file", ifcfile.value)
        console.log(formData)
        axios({
          baseURL: url,
          url: "/job/ifc/" + jobid.value,
          data: formData,
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token.value,
          },
          onUploadProgress: function (progressEvent: any) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            progress.value = percentCompleted
            console.log(percentCompleted)
          },
        })
          .then((resp) => {
            console.log(resp)
            // if (resp.data.project == project.value._id) {
            // project.value.jobs.push(resp.data)
            // }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }

    const sendEmail = async () => {
      await checkToken()

      if (hasPerm("system.admin")) {
        axios({
          baseURL: url,
          url: "/email",
          data: {},
          method: "POST",
          headers: {
            Authorization: "Bearer " + token.value,
          },
        })
          .then((resp) => {
            console.log(resp)
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }

    loadProjects()
    return {
      islogedIn: computed(() => store.getters["auth/isLogedIn"]),
      username: computed(() => store.getters["auth/username"]),
      login,
      logout,
      hasPerm,
      pname,
      addproject,
      getproject,
      delproject,
      addjob,
      ifcfile,
      uploadifc,
      projects,
      project,
      jobid,
      progress,
      sendEmail,
    }
  },
})
</script>


<template>
  <div class="test">
    <br />
    project list:
    <ul>
      <li v-for="project in projects" :key="project._id">
        <div class="projectItem">
          <div class="del" @click="delproject(project._id)">Del</div>
          <p @click="getproject(project._id)">{{ project.name }}</p>
        </div>
      </li>
      <li>
        <input v-model="pname" type="text" /><button @click="addproject">
          Add
        </button>
      </li>
    </ul>
    Project:
    <div v-if="project != {}">
      <p>name: {{ project.name }}</p>
      <ul>
        <li
          v-for="(job, i) in project.jobs"
          :key="job._id"
          @click="jobid = job._id"
        >
          Itteration{{ i }}
        </li>
      </ul>
      <button @click="addjob(project._id)">New Job</button>
    </div>
    <label
      >File
      <input
        type="file"
        id="file"
        ref="file"
        v-on:change="ifcfile = $refs.file.files[0]"
      />
    </label>
    <button v-on:click="uploadifc()">uploadifc</button>
    <button @click="sendEmail">send email</button>
    <div class="progressbar">
      <div class="progress" :style="{ width: progress + '%' }"></div>
    </div>
    email:<input type="text" />pass <input type="text" />
    <button v-on:click="login('info@trees.no', '1234admin')">Login</button>
    <button v-on:click="logout">Logout</button>
  </div>
</template>


<style scoped lang="scss">
.progressbar {
  width: 300px;
  height: 30px;
  background-color: white;
  border: 1px solid black;
  .progress {
    height: 100%;
    background-color: green;
  }
}

.projectItem {
  display: flex;
  .del {
    margin-bottom: 0px;
    // margin-right: 10px;
    width: 45px;
    height: 30px;
    border-radius: 10px;
    background-color: red;
    // padding: 3px;
  }
}
.test {
  margin-top: 150px;
  color: #000;
  * {
    color: #000;
  }
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 300px;
}

li {
  font: 200 20px/1.5 Helvetica, Verdana, sans-serif;
  border-bottom: 1px solid #ccc;
}

li:last-child {
  border: none;
}

li a {
  text-decoration: none;
  color: #000;
  display: block;
  width: 200px;

  -webkit-transition: font-size 0.3s ease, background-color 0.3s ease;
  -moz-transition: font-size 0.3s ease, background-color 0.3s ease;
  -o-transition: font-size 0.3s ease, background-color 0.3s ease;
  -ms-transition: font-size 0.3s ease, background-color 0.3s ease;
  transition: font-size 0.3s ease, background-color 0.3s ease;
}

li a:hover {
  font-size: 30px;
  background: #f6f6f6;
}
</style>
